import React from 'react'
import { useCopyToClipboard } from '@/utils'
import { useBooleanToggle } from '@codeleap/common'
import { EmbedModal, ItemsModal, ItemsModalProps } from '../Modals'

export type PaceCalculatorShareModalProps = Omit<ItemsModalProps, 'items'>

export const PaceCalculatorShareModal = (props: PaceCalculatorShareModalProps) => {
  const { copied, copyToClipboard, setCopied } = useCopyToClipboard()
  const [embedModalVisible, toggleEmbedModal] = useBooleanToggle(false)

  const siteUrl = process.env.GATSBY_SITE_URL
  const iframeSource =
    `<iframe 
      src="${siteUrl}resources/pace-calculator/embed" 
      style="width:100%; height:100vh; border:0;" 
      frameborder="0" 
      allowfullscreen>
    </iframe>
    `

  const handleCopy = () => {
    copyToClipboard(`${siteUrl}/resources/pace-calculator`)
  }

  const handleToggle = () => {
    setCopied(false)
    props.toggle?.()
  }

  return (
    // @ts-ignore
    <React.Fragment>
      <ItemsModal
        {...props}
        toggle={handleToggle}
        title='Share'
        items={[
          {
            text: copied ? 'Copied!' : 'Copy link',
            leftIcon: 'copy',
            rightIcon: copied ? 'check-circle' : null,
            onPress: handleCopy,
          }, {
            text: 'Embed',
            leftIcon: 'embed',
            onPress: toggleEmbedModal,
          }]}
      />

      <EmbedModal
        title='Embed'
        showBack
        iframeSource={iframeSource}
        visible={embedModalVisible}
        onBack={toggleEmbedModal}
        toggle={toggleEmbedModal}
      />
    </React.Fragment>
  )
}
