import React from 'react'
import { View } from '@/components'
import { ViewProps } from '@codeleap/web'
import { variantProvider } from '@/app'

export type AnimationsProps = ViewProps<'div'> & {
  height?: number
  width?: number
}

export const Animations: React.FC<AnimationsProps> = (props) => {
  const {
    height,
    width,
    ...containerProps
  } = props

  return (
    <View
      {...containerProps}
    >
      <View style={styles.progressBar} />
    </View>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  progressBar: {
    border: '5px solid #F2F9FF',
    borderTop: '5px solid #4cbae5',
    borderRadius: '50%',
    width: '60%',
    height: '60%',
    overflow: 'hidden',
    scale: 0.5,
    animation: 'spin 1s linear infinite',
    aspectRatio: 1,
  },
}), true)
