import { NavigationCategory } from '@/types'
import { createSlice } from '@codeleap/common'

type NavigationCategoriesState = {
  header: NavigationCategory[]
  isHeaderLoading: boolean
  isFooterLoading: boolean
  footer: NavigationCategory[]
}

const initialState: NavigationCategoriesState = {
  header: [],
  isHeaderLoading: false,
  isFooterLoading: false,
  footer: [],
}

export const navigationCategoriesSlice = createSlice({
  name: 'NavigationCategories',
  initialState,
  reducers: {
    setHeaderCategories: (_, categories: NavigationCategory[]) => {
      return {
        header: categories,
      }
    },
    setFooterCategories: (_, categories: NavigationCategory[]) => {
      return {
        footer: categories,
      }
    },
    setIsHeaderLoading: (_, isLoading: boolean) => {
      return {
        isHeaderLoading: isLoading,
      }
    },
    setIsFooterLoading: (_, isLoading: boolean) => ({
      isFooterLoading: isLoading,
    }),
  },
  asyncReducers: {},
})
