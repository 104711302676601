import { api } from '@/app'
import { useEffect } from '@codeleap/common'
import { graphql, useStaticQuery } from 'gatsby'
import { NavigationCategories, store, useAppSelector } from '@/redux'

const BASE_URL = 'crm/web-categories/'

export const useFooterCategories = (root = false) => {
  const freshedData = useAppSelector(
    state => state.NavigationCategories?.footer
  )

  useEffect(() => {
    const fetch = async () => {
      try {
        NavigationCategories.setIsFooterLoading(true)
        const response = await api.get(
          `${process.env.GATSBY_API_URL}${BASE_URL}`,
          {
            params: {
              type: 'FOOTER',
              limit: 1000,
            },
          }
        )
        NavigationCategories.setFooterCategories(response?.data?.results)
      } finally {
        NavigationCategories.setIsFooterLoading(false)
      }
    }

    const isFooterLoading =
      store.getState().NavigationCategories?.isFooterLoading
    if (root && !isFooterLoading) {
      fetch()
    }
  }, [root])

  const data = useStaticQuery(graphql`
    query FooterCategories {
      footerCategories {
        categories {
          id
          title
          type
          link
          level
          parent {
            id
            title
            type
            link
            level
          }
          children {
            id
            title
            type
            link
            level
          }
        }
      }
    }
  `)

  const categories = freshedData || data.footerCategories.categories
  return [...new Map(categories.map(item => [item.id, item])).values()]
}
