import { createRedux } from '@codeleap/common'
import { appStatusSlice } from './AppStatus'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { userLocationSlice } from './UserLocation'
import { mapSlice } from './Map'
import { exploreMapSlice } from './ExploreMap'
import { homeSlice } from './Home'
import { navigationCategoriesSlice } from './NavigationCategories'

const middlewares = []

// if (__DEV__) {
//   // eslint-disable-next-line @typescript-eslint/no-var-requires
//   const reduxDebugger = require('redux-flipper').default
//   middlewares.push(reduxDebugger({}))
// }

export const {
  store,
  actions: {
    AppStatus,
    UserLocation,
    MapRedux,
    ExploreMapRedux,
    Home,
    NavigationCategories,
  },
} = createRedux(
  {
    AppStatus: appStatusSlice,
    UserLocation: userLocationSlice,
    MapRedux: mapSlice,
    ExploreMapRedux: exploreMapSlice,
    Home: homeSlice,
    NavigationCategories: navigationCategoriesSlice,
  },
  {}
)

export type RootState = ReturnType<typeof store.getState>
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export * from './AppStatus'
export * from './UserLocation'
export * from './Map'
export * from './ExploreMap'
