import { api } from '@/app'
import { graphql, useStaticQuery } from 'gatsby'
import { useEffect } from '@codeleap/common'
import { NavigationCategories, store, useAppSelector } from '@/redux'

const BASE_URL = 'crm/web-categories/'
export const useHeaderCategories = (root = false) => {
  const freshedData = useAppSelector(
    state => state.NavigationCategories?.header
  )

  useEffect(() => {
    const fetch = async () => {
      try {
        NavigationCategories.setIsHeaderLoading(true)
        const response = await api.get(
          `${process.env.GATSBY_API_URL}${BASE_URL}`,
          {
            params: {
              type: 'HEADER',
              limit: 1000,
            },
          }
        )
        NavigationCategories.setHeaderCategories(response?.data?.results)
      } finally {
        NavigationCategories.setIsHeaderLoading(false)
      }
    }

    const isHeaderLoading =
      store.getState().NavigationCategories?.isHeaderLoading

    if (root && !isHeaderLoading) {
      fetch()
    }
  }, [root])

  const data = useStaticQuery(graphql`
    query HeaderCategories {
      headerCategories {
        categories {
          id
          title
          type
          link
          level
          parent {
            id
            title
            type
            link
            level
          }
        }
      }
    }
  `)

  const categories = freshedData || data.headerCategories.categories

  return [...new Map(categories.map(item => [item.id, item])).values()]
}
