import { onMount } from '@codeleap/common'
import { LocationUtils } from '../location'
import { UserLocation } from '@/redux'
import { CookiesKeys } from '@/app'
import Cookies from 'js-cookie'
import { LongCoords } from '@/types'
import { UserLocationUtils } from '../UserLocation'

export type useUpdateLocationProps = {
  canAskLocationPermission?: boolean
}

const updateCityName = (location: LongCoords) => {
  LocationUtils.getLocationDetails({ lat: location?.latitude, lng: location?.longitude }).then((response) => {
    const { data: details } = response

    const updatedLocation = { ...location, city: LocationUtils.getCityName(details) as string }

    UserLocation.setLocation(updatedLocation)

    Cookies.set(CookiesKeys.USER_LOCATION, JSON.stringify(updatedLocation))
  }).catch((error) => {
    logger.error('Failed to get location details:', error)

    const locationWithoutCity = { ...location, city: null }

    UserLocation.setLocation(locationWithoutCity)
    Cookies.set(CookiesKeys.USER_LOCATION, JSON.stringify(locationWithoutCity))
  })
}

const updateLocationRedux = (pos: GeolocationPosition) => {
  const data = LocationUtils.transformGeolocationPosition(pos)

  UserLocation.setLocation({
    latitude: data?.latitude,
    longitude: data?.longitude,
  })

  updateCityName(data)
}

const updateCurrentLocationRedux = () => {
  const { location } = UserLocationUtils.getCurrentLocation()

  UserLocation.setLocation({
    latitude: location?.latitude,
    longitude: location?.longitude,
  })
}

const updateIpInfoRedux = () => {
  LocationUtils.getIPInfo().then((response) => {
    const { data } = response
    const [latitude, longitude] = data.loc?.split(',')
    UserLocation.setIPInfo({ ...data, latitude, longitude })
    Cookies.set(CookiesKeys.IP_INFO, JSON.stringify({ ...data, latitude, longitude }))
  })
}

export const useUpdateLocation = ({ canAskLocationPermission = false }: useUpdateLocationProps) => {
  onMount(() => {
    LocationUtils.getLocation(updateLocationRedux, updateCurrentLocationRedux, canAskLocationPermission)

    updateIpInfoRedux()
  })
}
